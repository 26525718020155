<script setup lang="ts">
import { getAccessibleTextColor, useI18n } from '@/util';
import LscIcon from '../../media/icon/LscIcon.vue';
import LscOverflowEllipsis from '../overflow/LscOverflowEllipsis.vue';
import {
  type LscChipSize,
  LscChipSizes,
  type LscChipTag,
  LscChipTags,
  type LscChipVariant,
  LscChipVariants,
} from './LscChip.types.js';

const props = defineProps({
  /** The HTML tag to use for the chip. */
  is: {
    type: String as PropType<LscChipTag>,
    default: 'div',
    validator: (value: LscChipTag) => LscChipTags.includes(value),
  },
  /** The variant of the chip. */
  variant: {
    type: String as PropType<LscChipVariant>,
    default: 'default',
    validator: (value: LscChipVariant) => LscChipVariants.includes(value),
  },
  /** The size of the chip. */
  size: {
    type: String as PropType<LscChipSize>,
    default: 'lg',
    validator: (value: LscChipSize, { variant }: { variant: LscChipVariant }) => {
      if (variant === 'tag' && !['xs', 'sm'].includes(value)) {
        // eslint-disable-next-line no-console
        console.warn('The tag variant only accepts `xs` and `sm` sizes');
        return false;
      }
      return LscChipSizes.includes(value);
    },
  },
  /** Used to set the button's active state for popovers and dropdown menus.*/
  ariaExpanded: {
    type: [Boolean, String] as PropType<Booleanish>,
    default: false,
  },
  /** Used to define the buttons popup role for popovers and dropdown menus. */
  ariaHasPopup: {
    type: [Boolean, String] as PropType<Booleanish | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'>,
    default: false,
  },
  /**
   * Whether the chip is disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the chip is clearable.
   */
  clearable: {
    type: Boolean,
    default: false,
  },
  /**
   * The tooltip to show when hovering over the clear button.
   */
  clearTooltip: {
    type: String,
    default: '',
  },
  /**
   * Whether the clear button should always be shown.
   */
  alwaysShowClearButton: {
    type: Boolean,
    default: false,
  },
  /** The icon to show in the chip. */
  prependIcon: {
    type: String as PropType<LscIconName>,
    default: undefined,
  },
  /**
   * The color of the chip.
   * NOTE: Currenly only used and accepted for the tag variant.
   */
  color: {
    type: String,
    default: undefined,
    validator: (value: string, { variant }: { variant: LscChipVariant }) => variant === 'tag' || value === undefined,
  },
});

const emit = defineEmits(['clear']);
const { t } = useI18n();
const buttonType = computed(() => (props.is === 'button' ? 'button' : undefined));

const iconSizeMap = {
  xs: 'xs',
  sm: 'sm',
  lg: 'md',
} as const;

const iconSize = computed(() => iconSizeMap[props.size]);

const clearTooltip = computed(() => props.clearTooltip || t('Clear'));

const chipVariantStyleConfig = tv({
  base: 'group/LscChip relative box-border inline-flex items-center rounded-full transition-colors',
  slots: {
    prepend: 'flex shrink-0 items-center justify-center',
    text: 'mx-1',
    icon: 'text-icon-subtle',
    clearButton: 'flex shrink-0 items-center justify-center',
  },
  variants: {
    size: {
      xs: {
        base: 'h-[--lsds-c-chip-height-xs] max-w-40 px-0.5 text-body-2',
      },
      sm: {
        base: 'h-[--lsds-c-chip-height-sm] max-w-40 px-1 text-body-2',
      },
      lg: {
        base: 'h-[--lsds-c-chip-height-lg] max-w-48 px-1 text-body-1',
      },
    },
    variant: {
      'default': {
        base: 'bg-[--lsds-c-chip-color-background-default-default] text-default',
      },
      'on-surface': {
        base: [
          'bg-[--lsds-c-chip-color-background-on-surface-default] text-default',
          'border border-[--lsds-c-chip-color-border-on-surface-default]',
        ],
      },
      'tag': {
        base: 'bg-[--lsc-chip-custom-bg-color] text-[--lsc-chip-custom-text-color]',
        icon: 'text-[--lsc-chip-custom-text-color]',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      is: 'button',
      class: {
        base: [
          'hover:bg-[--lsds-c-chip-color-background-default-hover]',
          'aria-expanded:bg-[--lsds-c-chip-color-background-default-pressed]',
        ],
      },
    },
    {
      variant: 'on-surface',
      is: 'button',
      class: {
        base: 'hover:border-[--lsds-c-chip-color-border-on-surface-hover]',
      },
    },
    {
      variant: 'tag',
      size: 'xs',
      class: {
        clearButton: 'px-0.5',
      },
    },
    {
      variant: 'tag',
      size: ['sm', 'lg'],
      class: {
        clearButton: 'px-1',
      },
    },
    {
      variant: 'tag',
      alwaysShowClearButton: false,
      class: {
        clearButton:
          'absolute inset-y-0 right-0 rounded-r-full bg-[--lsc-chip-custom-bg-color] opacity-0 group-hover/LscChip:opacity-100',
      },
    },
  ],
});

const classes = computed(() =>
  chipVariantStyleConfig({
    is: props.is,
    size: props.size,
    variant: props.variant,
    alwaysShowClearButton: props.alwaysShowClearButton,
  }),
);

const shouldShowClearButton = computed(() => props.clearable && !props.disabled);

const style = computed(() => {
  if (props.variant !== 'tag') {
    return {};
  }
  return {
    '--lsc-chip-custom-bg-color': props.color ?? 'var(--lsds-c-chip-color-background-default-default)',
    '--lsc-chip-custom-text-color': props.color
      ? getAccessibleTextColor(props.color)
      : 'var(--lsds-a-color-text-default)',
  };
});
</script>

<template>
  <Component
    :is="is"
    :type="buttonType"
    :class="classes.base()"
    :aria-expanded="ariaExpanded"
    :aria-haspopup="ariaHasPopup || ariaExpanded"
    :style="style"
    :aria-disabled="disabled"
    :disabled="disabled"
  >
    <div v-if="prependIcon || $slots.prepend" :class="classes.prepend()">
      <slot name="prepend">
        <LscIcon :icon="prependIcon" :size="iconSize" :class="classes.icon()" />
      </slot>
    </div>

    <LscOverflowEllipsis :class="classes.text()"><slot /></LscOverflowEllipsis>

    <button
      v-if="shouldShowClearButton"
      v-LsdTooltip="clearTooltip"
      :aria-label="clearTooltip"
      :class="classes.clearButton()"
      type="button"
      @click="emit('clear', $event)"
    >
      <LscIcon icon="lsi-close" :size="iconSize" />
    </button>
  </Component>
</template>
