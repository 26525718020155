<script setup>
import { useI18n } from '@/util';
import { useFormulaData } from './useFormulaData.js';
import { insertFormulaChip } from './utils';

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
  editor: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['showOption']);

const { t } = useI18n();
const { formulaFunctions, formulaOperators } = useFormulaData();
const functions = formulaFunctions.map(({ name, description, syntax }) => ({
  name,
  description,
  syntax,
  type: 'function',
}));
const operators = formulaOperators.map(({ name, description, operator, icon, syntax }) => ({
  name,
  description,
  operator,
  icon,
  syntax,
  type: 'operator',
}));

function insertChip(item) {
  if (!props.editor) return;

  // For example, always insert at doc end
  insertFormulaChip(props.editor, item, {
    insertAtEnd: true,
    shouldFocus: true,
    fromOptionsPanel: true,
  });
}

function insertOperator(operator) {
  if (!props.editor) return;

  const endPos = props.editor.state.doc.content.size;

  props.editor.chain().setTextSelection(endPos).insertContent(` ${operator.operator} `).run();
  props.editor.view.dispatch(props.editor.state.tr);
}

const hoveredItem = shallowRef(null);

function hoverItem(item) {
  hoveredItem.value = item;
  emit('showOption', item);
}
</script>

<template>
  <div class="flex h-full w-48 flex-col border-x border-separator">
    <div class="flex-grow overflow-y-auto px-4 py-2">
      <LscAccordion>
        <LscAccordionItem name="functions" :title="t('Functions')" class="border-none">
          <div class="grid auto-cols-auto gap-0">
            <button
              v-for="func in functions"
              :key="func.name"
              type="button"
              class="group flex items-center rounded-md text-left text-body-2 hover:bg-hover focus-visible:bg-hover"
              :class="{ 'bg-hover font-semibold': hoveredItem === func }"
              @click="insertChip(func)"
              @mouseenter="hoverItem(func)"
            >
              <LscIcon icon="lsi-formula-function" size="sm" class="m-2 text-icon-subtle" />

              <LscOverflowEllipsis class="flex-1">
                {{ func.name }}
              </LscOverflowEllipsis>

              <LscIconButton
                v-LsdTooltip="t('Add operator')"
                class="m-2 opacity-0 group-hover:opacity-100"
                icon="lsi-add"
                size="xs"
              />
            </button>
          </div>
        </LscAccordionItem>
        <LscAccordionItem name="fields" :title="t('Fields')" class="border-none">
          <div class="grid auto-cols-auto gap-0">
            <button
              v-for="field in fields"
              :key="field.name"
              type="button"
              class="group flex items-center rounded-md text-left text-body-2 hover:bg-hover focus-visible:bg-hover"
              :class="{ 'bg-hover font-semibold': hoveredItem === field }"
              @click="insertChip(field)"
              @mouseenter="hoverItem(field)"
            >
              <LscIcon :icon="field.icon" size="sm" class="m-2 text-icon-subtle" />

              <LscOverflowEllipsis class="flex-1">
                {{ field.name }}
              </LscOverflowEllipsis>

              <LscIconButton
                v-LsdTooltip="t('Add field')"
                class="m-2 opacity-0 group-hover:opacity-100"
                icon="lsi-add"
                size="xs"
              />
            </button>
          </div>
        </LscAccordionItem>
        <LscAccordionItem name="operators" :title="t('Operators')" class="border-none">
          <div class="grid auto-cols-auto gap-0">
            <button
              v-for="operator in operators"
              :key="operator.name"
              type="button"
              class="group flex items-center rounded-md text-left text-body-2 hover:bg-hover focus-visible:bg-hover"
              :class="{ 'bg-hover font-semibold': hoveredItem === operator }"
              @click="insertOperator(operator)"
              @mouseenter="hoverItem(operator)"
            >
              <LscIcon :icon="operator.icon" size="sm" class="m-2 text-icon-subtle" />

              <LscOverflowEllipsis class="flex-1">
                {{ operator.name }}
              </LscOverflowEllipsis>

              <LscIconButton
                v-LsdTooltip="t('Add operator')"
                class="m-2 opacity-0 group-hover:opacity-100"
                icon="lsi-add"
                size="xs"
              />
            </button>
          </div>
        </LscAccordionItem>
      </LscAccordion>
    </div>

    <div class="flex shrink-0 items-center gap-2 border-t p-4 hover:cursor-pointer" @mouseenter="hoverItem(null)">
      <LscIcon icon="lsi-help" size="sm" class="text-icon-subtle" />
      <span class="text-body-2">{{ t('How to start?') }}</span>
    </div>
  </div>
</template>
