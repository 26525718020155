import { Extension, Node } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import CustomfieldAddOrEditAdvancedFormulaChipNodeWrapper from './CustomfieldAddOrEditAdvancedFormulaChipNodeWrapper.vue';

/**
 * Parses a formula string into Tiptap JSON structure for editing/copying formula
 * @param {string} formulaString - The string representing a formula (e.g. `"SUM" ("Profit" - "Total cost")`).
 * @param {Object<string, string>} knownColumns - An object mapping column names to their icon (e.g. { 'Profit': 'lsi-time', ... }).
 * @returns {Object|null} A Tiptap JSON object representing the document with chips and text nodes, or null if formulaString is falsy.
 */
export function parseFormulaStringToTiptapJson(formulaString, knownColumns, formulaNames) {
  if (!formulaString) return null;

  const content = [];
  let currentText = '';
  let inQuotes = false;
  let quoteChar = null;
  let quoteStart = 0;

  const chars = [...formulaString];
  chars.forEach((char, i) => {
    if (char === '"' || char === "'") {
      if (inQuotes && char === quoteChar) {
        // End of quoted text
        const quotedText = formulaString.slice(quoteStart, i);
        if (currentText) {
          content.push({
            type: 'text',
            text: currentText,
          });
          currentText = '';
        }

        // Determine if it's a function or column
        const isFunction = formulaNames.map((f) => f.name.toUpperCase()).includes(quotedText.toUpperCase());

        content.push({
          type: 'formulaChip',
          attrs: {
            type: isFunction ? 'function' : 'column',
            value: quotedText,
            icon: isFunction ? 'lsi-customfield-formula' : knownColumns[quotedText],
            color: isFunction ? undefined : '#E6F0FF',
          },
        });
        inQuotes = false;
        quoteChar = null;
      } else if (!inQuotes) {
        if (currentText) {
          content.push({
            type: 'text',
            text: currentText,
          });
          currentText = '';
        }
        quoteStart = i + 1;
        inQuotes = true;
        quoteChar = char;
      }
    } else if (!inQuotes) {
      currentText += char;
    }
  });

  // Add any remaining text after the loop
  if (currentText) {
    content.push({
      type: 'text',
      text: currentText,
    });
  }

  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content,
      },
    ],
  };
}

export const customfieldAdvancedFormulaChipExtension = Node.create({
  name: 'formulaChip',

  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      type: {
        default: 'column',
      },
      value: {
        default: '',
      },
      color: {
        default: undefined,
      },
      icon: {
        default: undefined,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="formula-chip"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { ...HTMLAttributes, 'data-type': 'formula-chip' }];
  },

  addNodeView() {
    return VueNodeViewRenderer(CustomfieldAddOrEditAdvancedFormulaChipNodeWrapper);
  },
});

/**
 * Creates a ProseMirror plugin for to turn operators and parentheses in the formula editor blue
 * @param {Object} options - Configuration options
 * @param {Array} options.formulaOperators - Array of operator objects with operator and name properties
 * @returns {Plugin} ProseMirror plugin for operator decoration
 */
function createOperatorDecorationPlugin(options) {
  let decorationSet = DecorationSet.empty;

  return new Plugin({
    props: {
      decorations(state) {
        const { doc } = state;
        const { formulaOperators } = options;

        const allOperators = [...formulaOperators, { operator: '(' }, { operator: ')' }];

        const operatorPattern = allOperators
          .map((op) => {
            return op.operator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          })
          .join('|');
        const regex = new RegExp(`(${operatorPattern})`, 'g');

        const decorations = [];
        doc.descendants((node, pos) => {
          if (node.isText && node.text) {
            for (const match of node.text.matchAll(regex)) {
              const start = pos + match.index;
              const end = start + match[0].length;
              decorations.push(
                Decoration.inline(start, end, {
                  class: 'formula-operator',
                  style: 'color: #4461D7 !important;', // TODO: replace hex when we have token
                }),
              );
            }
          }
          return true;
        });

        decorationSet = DecorationSet.create(doc, decorations);
        return decorationSet;
      },
    },
  });
}

export const OperatorDecorationExtension = Extension.create({
  name: 'operatorDecoration',

  addOptions() {
    return {
      formulaOperators: [],
    };
  },

  addProseMirrorPlugins() {
    return [createOperatorDecorationPlugin(this.options)];
  },
});

/**
 * Inserts a formula chip into a formula editor
 * inserting parentheses ( ) if chip is a "function",
 * then placing the cursor/focus exactly where we want.
 *
 * @param {Object} editor - The tiptap editor instance
 * @param {Object} item   - The item being inserted (field or function)
 * @param {Object} options
 * @param {number} [options.insertPos]   - Where to insert; if omitted, use current selection
 * @param {boolean} [options.insertAtEnd] - If true, insert at the doc end
 * @param {string} [options.searchText]  - If you're removing typed text before inserting
 * @param {boolean} [options.shouldFocus] - If we should re-focus editor afterwards
 * @param {boolean} [options.fromOptionsPanel] - Needed to position cursor correctly for parentheses

 */
export function insertFormulaChip(
  editor,
  item,
  { insertPos, insertAtEnd = false, searchText = '', shouldFocus = true, fromOptionsPanel = false } = {},
) {
  if (!editor) return;

  let { from, to } = editor.state.selection;
  if (typeof insertPos === 'number') {
    from = insertPos;
    to = insertPos;
  } else if (insertAtEnd) {
    const docEnd = editor.state.doc.content.size;
    from = docEnd;
    to = docEnd;
  }

  if (searchText.length) {
    from = from - searchText.length;
  }

  editor
    .chain()
    .setTextSelection({ from, to })
    .deleteRange({ from, to })
    .insertContent({
      type: 'formulaChip',
      attrs: {
        type: item.type,
        value: item.name,
        icon: item.type === 'function' ? 'lsi-customfield-formula' : item.icon || 'lsi-field-default',
      },
    })
    .run();

  if (item.type === 'function') {
    editor
      .chain()
      .insertContent([
        { type: 'text', text: '(' },
        { type: 'text', text: ' ' },
        { type: 'text', text: ')' },
        { type: 'text', text: ' ' },
      ])
      .run();
  }

  nextTick(() => {
    if (!shouldFocus || !editor) return;

    // when added from option vs from editor we need different amount to step back for cursor
    const functionPos = fromOptionsPanel ? 3 : 1;
    const pos = editor.state.selection.from;
    editor
      .chain()
      .focus()
      .setTextSelection(item.type === 'function' ? pos - functionPos : pos + 1)
      .run();
  });
}
