<script setup>
import { loaderStatePromise, useCustomfieldActions, useCustomfieldV3Loader, useFeatures, usePermissions } from '@/api';
import { getRandomPickerColor, useI18n, useLocalStorage } from '@/util';
import { FeatureLimitUpgradeDialog } from '@/module/featureLimit';
import { FeedbackButton } from '@/module/feedback';
import { PlanPromoteChip } from '@/module/planTrial';
import { customfieldEntities, customfieldTypes } from '../constants';
import { useCustomfieldTracking } from '../useCustomfieldTracking';
import CustomfieldAddOrEditAdvancedFormulaView from './advanced/CustomfieldAddOrEditAdvancedFormulaView.vue';
import CustomfieldAddOrEditDialogFieldTypes from './CustomfieldAddOrEditDialogFieldTypes.vue';
import CustomfieldAddOrEditDialogFormula from './CustomfieldAddOrEditDialogFormula.vue';
import CustomfieldAddOrEditDialogOptions from './CustomfieldAddOrEditDialogOptions.vue';

const props = defineProps({
  customfield: {
    type: Object,
    default: null,
  },
  /**
   * Which entity the customfield is for.
   * @type {PropType<customfieldEntities[keyof typeof customfieldEntities]>}
   */
  entity: {
    type: String,
    required: true,
    validator: (value) => Object.values(customfieldEntities).includes(value),
  },
  project: {
    type: Object,
    default: null,
  },
  canToggleProjectSpecific: {
    type: Boolean,
    default: false,
  },
  canAddFormula: {
    type: Boolean,
    default: false,
  },
  defaultCustomFieldType: {
    type: String,
    default: customfieldTypes.TEXT,
  },
});

const emit = defineEmits(['close', 'created', 'deleted', 'updated']);

const { t } = useI18n();
const {
  trackAddTaskCustomfieldModalViewed,
  trackAddProjectCustomfieldModalViewed,
  trackTaskCustomfieldAdded,
  trackProjectCustomfieldAdded,
} = useCustomfieldTracking();
const { createCustomfield, updateCustomfield, deleteCustomfield } = useCustomfieldActions();
const { canManageCustomfields } = usePermissions();
const { advancedFormulaFieldsEnabled, formulaFieldsEnabled } = useFeatures();

// We currently use `customfieldId` only for loading the `count` (the number of entities
// that the customfield is attached to), so we can implement `isGettingCount` as it is below.
const customfieldId = shallowRef();
const isGettingCount = computed(() => Number.isInteger(customfieldId.value));
const state = useCustomfieldV3Loader({ customfieldId, params: { getCount: true } });

const isSubmitting = shallowRef(false);
const isConfirmDeleteDialogOpen = shallowRef(false);
const isDeleting = shallowRef(false);
const formulaArguments = shallowRef([]);
const isProjectSpecific = shallowRef(Boolean(props.project?.id));
const selectedProject = ref(props.project ? { ...props.project } : null);

// Add localStorage for formula toggle for testing, will be removed
const showAdvancedFormula = useLocalStorage('teamwork/CustomFields/showAdvancedFormula', false);

const defaultCustomfield = shallowRef({
  name: '',
  description: '',
  unitId: null,
  type: props.defaultCustomFieldType,
  options: {
    choices: [
      { value: t('Option 1'), color: getRandomPickerColor() },
      { value: t('Option 2'), color: getRandomPickerColor() },
    ],
  },
});
const customfield = ref(props.customfield || defaultCustomfield.value);
const entity = shallowRef(customfield.value?.entity || props.entity);

const isLimitDialogOpen = shallowRef(false);

const isEditing = computed(() => customfield.value?.id > 0);

const saveButtonText = computed(() => (isEditing.value ? t('Update field') : t('Save field')));

const isMultiChoice = computed(() =>
  [customfieldTypes.DROPDOWN, customfieldTypes.STATUS].includes(customfield.value?.type),
);

const isFormula = computed(
  () =>
    (customfield.value.formula || customfield.value.type === customfieldTypes.FORMULA) &&
    formulaFieldsEnabled.value &&
    props.canAddFormula,
);

const customfieldType = computed({
  get: () => (isFormula.value ? customfieldTypes.FORMULA : customfield.value.type),
  set: (value) => {
    customfield.value.type = value;
  },
});

const showProjectSpecificToggle = computed(() => {
  if (props.canToggleProjectSpecific) {
    return true;
  }
  if (!(isEditing.value || isFormula.value) && entity.value === customfieldEntities.TASK) {
    return true;
  }
  return false;
});

const saveButtonEnabled = computed(() => {
  if (customfield.value.name === '') {
    return false;
  }

  if (isFormula.value) {
    return customfield.value.formula;
  }

  if (isMultiChoice.value) {
    const opts = customfield.value.options.choices;
    if (opts.length === 0) {
      return false;
    }
    for (let i = 0; i < opts.length; i++) {
      if (opts[i].value === '') {
        return false;
      }
    }
  }

  return !isDeleting.value;
});

function close() {
  emit('close');
}

async function save() {
  try {
    isSubmitting.value = true;
    const cf = { ...customfield.value, entity: entity.value };
    cf.options = isMultiChoice.value ? customfield.value.options : undefined;

    if (isProjectSpecific.value && showProjectSpecificToggle.value && selectedProject.value?.id > 0) {
      cf.projectId = selectedProject.value.id;
    }

    if (cf.type === customfieldTypes.FORMULA) {
      cf.type = customfieldTypes.NUMBER_DECIMAL;
      cf.entity = `cr-${props.entity}`;
    } else if (!isEditing.value) {
      cf.formula = undefined;
      cf.unitId = undefined;
    }

    try {
      if (isEditing.value) {
        await updateCustomfield(cf);
        emit('updated', cf);
      } else {
        const newCustomfield = await createCustomfield(cf);
        emit('created', newCustomfield);
      }
    } catch (error) {
      error?.response?.data?.errors.forEach(({ detail }) => {
        if (detail === 'maximum number') {
          isLimitDialogOpen.value = true;
        }
      });
    }

    if (props.entity === customfieldEntities.TASK) {
      trackTaskCustomfieldAdded(customfieldType.value);
    } else if (props.entity === customfieldEntities.PROJECT) {
      trackProjectCustomfieldAdded(customfieldType.value);
    }

    if (!isLimitDialogOpen.value) {
      close();
    }
  } finally {
    isSubmitting.value = false;
  }
}

const deleteConfirmMessage = shallowRef('');

async function confirmDelete() {
  isConfirmDeleteDialogOpen.value = true;

  try {
    customfieldId.value = customfield.value.id;
    const count = (await loaderStatePromise(state)).meta.count || 0;

    switch (entity.value) {
      case customfieldEntities.CLIENT:
        deleteConfirmMessage.value = t(
          'This custom field is currently not attached to any client | This custom field is currently attached to one client | This custom field is currently attached to {n} clients',
          { n: count },
        );
        break;
      case customfieldEntities.PROJECT:
        deleteConfirmMessage.value = t(
          'This custom field is currently not attached to any project | This custom field is currently attached to one project | This custom field is currently attached to {n} projects',
          { n: 3 },
        );
        break;
      case customfieldEntities.TASK:
      default:
        deleteConfirmMessage.value = t(
          'This custom field is currently not attached to any task | This custom field is currently attached to one task | This custom field is currently attached to {n} tasks',
          { n: count },
        );
        break;
    }
  } finally {
    customfieldId.value = undefined;
  }
}

async function handleConfirmDelete() {
  try {
    isDeleting.value = false;
    await deleteCustomfield(customfield.value);
    emit('deleted');
    close();
  } finally {
    isDeleting.value = false;
  }
}

onMounted(() => {
  if (props.entity === customfieldEntities.TASK) {
    trackAddTaskCustomfieldModalViewed();
  } else if (props.entity === customfieldEntities.PROJECT) {
    trackAddProjectCustomfieldModalViewed();
  }
});
</script>

<template>
  <LscDialogCard
    :title="isEditing ? t('Edit custom field') : t('Create custom field')"
    :size="advancedFormulaFieldsEnabled && showAdvancedFormula && isFormula ? 'lg' : 'md'"
    @close="close"
  >
    <template #prependHeaderRight>
      <PlanPromoteChip class="ml-2" plan="grow" unlimited />
      <LscSwitch
        v-if="advancedFormulaFieldsEnabled && isFormula"
        v-model="showAdvancedFormula"
        data-identifier="customfield-formula-advanced-toggle"
        class="ml-2"
      >
        <template #label>{{ t('Advanced') }}</template>
      </LscSwitch>
      <FeedbackButton class="ml-2" data-identifier="feedback-custom-field-dialog" />
    </template>

    <CustomfieldAddOrEditAdvancedFormulaView
      v-if="showAdvancedFormula && isFormula"
      v-model:customfieldType="customfieldType"
      :customfield="customfield"
      :isEditing="isEditing"
      :entity="entity"
      :canAddFormula="canAddFormula"
    />

    <div v-else class="flex flex-col gap-x-6 gap-y-4">
      <div class="flex gap-3">
        <VTextField
          v-model.trim="customfield.name"
          :label="t('Field title')"
          density="compact"
          class="w-7/12"
          autofocus
          required
        />

        <CustomfieldAddOrEditDialogFieldTypes
          v-model="customfieldType"
          :disabled="isEditing"
          :canAddFormula="canAddFormula"
        />
      </div>

      <VTextarea v-model.trim="customfield.description" :label="t('Add more detail to this field')" rows="3" />

      <CustomfieldAddOrEditDialogOptions v-if="isMultiChoice" v-model="customfield.options.choices" />

      <div v-if="showProjectSpecificToggle">
        <LscSwitch v-model="isProjectSpecific" :disabled="!canManageCustomfields" class="mb-2">
          <template #label>
            <div class="flex items-center gap-2">
              {{ t('Project specific') }}
              <LscIcon
                v-LsdTooltip="
                  isProjectSpecific
                    ? t('This field will only be visible on this project')
                    : t('This field will be visible on all projects')
                "
                icon="lsi-tooltip"
                size="sm"
              />
            </div>
          </template>
        </LscSwitch>
        <KeepAlive>
          <LswProjectPicker v-if="isProjectSpecific && !project?.id" v-model="selectedProject" />
        </KeepAlive>
      </div>

      <CustomfieldAddOrEditDialogFormula
        v-if="isFormula && !showAdvancedFormula"
        v-model="customfield.formula"
        v-model:unitId="customfield.unitId"
        v-model:modelValueArray="formulaArguments"
        :editing="isEditing"
        :entity="entity"
      />
    </div>

    <template #actions>
      <div class="flex w-full items-center gap-2">
        <LscButton variant="tertiary" @click="close">
          {{ t('Cancel') }}
        </LscButton>
        <div class="flex-1" />
        <LscButton
          v-if="isEditing"
          variant="critical-secondary"
          :loading="isDeleting"
          :disabled="isSubmitting"
          @click="confirmDelete"
        >
          {{ t('Delete field') }}
        </LscButton>
        <LscButton
          variant="primary"
          :loading="isSubmitting"
          :disabled="!saveButtonEnabled"
          type="button"
          data-identifier="custom-field-add-edit-dialog--save-button"
          @click="save"
        >
          {{ saveButtonText }}
        </LscButton>
      </div>
    </template>
  </LscDialogCard>

  <LscDialogConfirm
    v-if="isEditing"
    v-model="isConfirmDeleteDialogOpen"
    critical
    :title="t('Confirm delete')"
    :confirmButtonText="t('Yes, delete custom field')"
    @confirm="handleConfirmDelete"
  >
    <LscLoadingProgress v-if="isGettingCount" />
    <template v-else>
      <div class="px-4">
        <LscReplace :text="t('Are you sure you want to delete {fieldName} custom field?')">
          <template #fieldName>
            <strong>{{ customfield.name }}</strong>
          </template>
        </LscReplace>
        <br />
        <span>
          {{ deleteConfirmMessage }}
        </span>
      </div>
    </template>
  </LscDialogConfirm>

  <FeatureLimitUpgradeDialog v-model="isLimitDialogOpen" feature="customfields" @close="close" />
</template>
